import mutations from './mutations';

jest.mock('@vivotek/lib-medama', () => ({
  PLUGINFREE: {
    SUPPORT_VIDEO_CODEC: [],
  },
}));

describe('mutations', () => {
  describe('setActiveVideo', () => {
    describe('alarm', () => {
      it('should update alarm playlist if active video is null', () => {
        const state = {
          currentType: 'alarm',
          alarm: {
            activeVideoId: 1,
            playList: [{}]
          }
        };
        mutations.setActiveVideo(state, {
          alarm: null,
          encoders: {},
        });
        expect(state.alarm.playList).toEqual([]);
        expect(state.alarm.activeVideoId).toEqual(-1);
      });

      it('should update alarm playlist', () => {
        const state = {
          currentType: 'alarm',
          alarm: {
            activeVideoId: 1,
            playList: [{}]
          }
        };
        mutations.setActiveVideo(state, {
          data: {
            id: 2,
            recording_info: [
              {
                name: 'camera 1',
                index: 1,
                recording_event: {
                  pre_event: 1,
                  post_event: 19
                },
                eventRecordingDuration: 10,
              },
              {
                name: 'camera 2',
                index: 2,
                recording_event: {
                  pre_event: 5,
                  post_event: 15
                },
                eventRecordingDuration: 20,
              },
              {
                name: 'camera 10',
                index: 10,
                recording_event: {
                  pre_event: 10,
                  post_event: 20
                },
                eventRecordingDuration: 30,
              }
            ],
            start: 10000000,
          },
          timezone: 0
        });
        expect(state.alarm.playList[0].options.begin.timestamp).toEqual(9000);
        expect(state.alarm.playList[0].options.end.timestamp).toEqual(29000);
        expect(state.alarm.activeVideoId).toEqual(2);
      });
    });
    describe('vca', () => {
      it('should update vca playlist if active video is null', () => {
        const state = {
          currentType: 'vca',
          vca: {
            activeVideoId: 1,
            playList: [{}]
          }
        };
        mutations.setActiveVideo(state, {
          vca: null,
          encoders: {},
        });
        expect(state.vca.playList).toEqual([]);
        expect(state.vca.activeVideoId).toEqual(-1);
      });

      it('should update vca playlist', () => {
        const state = {
          currentType: 'vca',
          vca: {
            activeVideoId: 1,
            playList: [{}]
          }
        };
        mutations.setActiveVideo(state, {
          data: {
            id: 2,
            recording_info: [
              {
                name: 'camera 1',
                index: 1,
                recording_event: {
                  pre_event: 1,
                  post_event: 19
                },
                eventRecordingDuration: 10,
              }
            ],
            start: 10000000,
          },
          timezone: 0
        });
        expect(state.vca.playList[0].options.begin.timestamp).toEqual(9000);
        expect(state.vca.playList[0].options.end.timestamp).toEqual(29000);
        expect(state.vca.activeVideoId).toEqual(2);
      });
    });
  });
  describe('goPreviousPage', () => {
    it('should return currentPageIndex - 1', () => {
      const state = {
        currentType: 'test',
        test: {
          currentPageIndex: 2
        }
      };
      mutations.goPreviousPage(state);
      expect(state.test.currentPageIndex).toEqual(1);
    });
  });
  describe('goNextPage', () => {
    it('should return currentPageIndex + 1', () => {
      const state = {
        currentType: 'test',
        test: {
          currentPageIndex: 2
        }
      };
      mutations.goNextPage(state);
      expect(state.test.currentPageIndex).toEqual(3);
    });
  });
  describe('setPlayingChannel', () => {
    it('should set playingChannel = 2', () => {
      const state = {
        currentType: 'alarm',
        alarm: {
          playingChannel: null
        }
      };
      mutations.setPlayingChannel(state, 2);
      expect(state.alarm.playingChannel).toEqual(2);
    });
  });
  describe('setExportList', () => {
    it('should set result = result', () => {
      const state = {
        currentType: 'test',
        test: {
          export: []
        }
      };
      const result = [1, 2, 3];
      mutations.setExportList(state, result);
      expect(state.test.export).toEqual(result);
    });
    it('should set result = []', () => {
      const state = {
        currentType: 'test',
        test: {
          export: [1, 2, 3]
        }
      };
      mutations.setExportList(state, []);
      expect(state.test.export).toEqual([]);
    });
  });
  describe('setPlayAll', () => {
    it('should set isPlayingAll = value', () => {
      const state = {
        currentType: 'test',
        test: {
          isPlayingAll: false
        }
      };
      const value = true;
      mutations.setPlayAll(state, value);
      expect(state.test.isPlayingAll).toEqual(value);
    });
  });
  describe('setExportStatus', () => {
    it('should set state.exportStatus if exportStatus is valid', () => {
      const STATE = {
        exportStatus: 'NORMAL'
      };

      mutations.setExportStatus(STATE, 'ERROR');

      expect(STATE.exportStatus).toBe('ERROR');
    });
    it('should not set state.exportStatus if exportStatus is invalid', () => {
      const STATE = {
        exportStatus: 'NORMAL'
      };

      mutations.setExportStatus(STATE, 'WRONG');

      expect(STATE.exportStatus).toBe('NORMAL');
    });
  });
});
